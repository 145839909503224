import { Container, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import Seo from "../components/Seo"

const useStyles = makeStyles({
  markName: {
    fontWeight: "bold",
  },
  typo: {
    marginTop: 24,
    textAlign: "left",
  },
})

const Trademark = ({ mark, children }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.typo}>
      <span className={classes.markName}>{mark}</span> {children}
    </Typography>
  )
}

const Trademarks = ({}) => {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title={"Trademarks"} />

      <Section>
        <Container maxWidth={"md"}>
          <Typography className={classes.typo} variant={"h1"}>
            Trademarks
          </Typography>
          <Typography className={classes.typo}>
            All the following trademarks are the properties of their respective
            owners as listed below. Should any trademark attribution be missing,
            mistaken or erroneous, please contact us at info[at]mobile.dev as
            soon as possible to rectify.
          </Typography>
          <Trademark mark="Android™">is a trademark of Google LLC.</Trademark>
          <Trademark mark="iOS®">
            is a registered trademark of Cisco Systems, Inc.
          </Trademark>
          <Trademark mark="iPad®">
            is a registered trademark of Apple Inc., registered in the U.S. and other countries.
          </Trademark>
          <Trademark mark="iPodS®">
            is a registered trademark of Apple Inc., registered in the U.S. and other countries.
          </Trademark>
          <Trademark mark="Gradle®">
            is a registered trademark of Gradle Inc.
          </Trademark>
          <Trademark mark="Java®">
            is a registered trademark of Oracle and/or its affiliates.
          </Trademark>
          <Trademark mark="Kotlin™">
            and the Kotlin logo are trademarks of the Kotlin Foundation.
          </Trademark>
          <Trademark mark="Linux®">
            is the registered trademark of Linus Torvalds in the U.S. and other
            countries.
          </Trademark>
          <Trademark mark="macOS®">
            is a registered trademark of Apple Inc., registered in the U.S. and other countries.
          </Trademark>
          <Trademark mark="NeverNull®">
            is a registered trademark of the NeverNull GmbH.
          </Trademark>
          <Trademark mark="Windows®">
            is a registered trademark of Microsoft Corporation in the United
            States and/or other countries.
          </Trademark>
        </Container>
      </Section>
    </Layout>
  )
}

export default Trademarks
